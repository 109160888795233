import React from "react";
import AsyncApiComponent from "@kyma-project/asyncapi-react";

import { ResistanceAsync } from "../async_resistance";
import "@kyma-project/asyncapi-react/lib/styles/fiori.css";
import "./../style.css";

const AsyncAPIForResistance = () => {
	return(
		<AsyncApiComponent schema={ResistanceAsync} />
	)
}

export default AsyncAPIForResistance;