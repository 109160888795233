import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import SwaggerUI from "swagger-ui-react"
import AsyncAPIForMafia from "./async_api_for_mafia"
import AsyncAPIForAvalon from "./async_api_for_avalon"
import AsyncAPIForResistance from "./async_api_for_resistance"
import AsyncAPIForWerewolf from "./async_api_for_werewolf"
import Cookies from "js-cookie"

import "swagger-ui-react/swagger-ui.css"
import Nav from "./Nav";

const UniversalSwaggerUIF = () => {
  return (
    <SwaggerUI url="http://3.221.25.157:8070/universal_api_doc" />
  )
};
const MafiaSwaggerUIF = () => {
  return (
    <SwaggerUI url="http://3.221.25.157:8070/mafia_api_doc" />
  )
};
const WerewolfSwaggerUIF = () => {
  return (
    <SwaggerUI url="http://3.221.25.157:8070/werewolf_api_doc" />
  )
};
const ResistanceSwaggerUIF = () => {
  return (
    <SwaggerUI url="http://3.221.25.157:8070/resistance_api_doc" />
  )
};
const AvalonSwaggerUIF = () => {
  return (
    <SwaggerUI url="http://3.221.25.157:8070/avalon_api_doc" />
  )
};
  
const NoMatch = () => {
    return (
      <div>
      <h1 style={{textAlign: "center", padding: "50px" }}>Page Not Found!</h1>
    </div>
  )
}

const Menu =  () => {
  const role = Cookies.get("role");
  console.log(`Cookies data : ${Cookies.get("role")}`);

  return (
    <Router>
      <Nav />
      <Switch>

        <Route path="/" exact component={(role ==='admin')? UniversalSwaggerUIF: UniversalSwaggerUIF} />

        <Route path="/mafia_doc" exact component={MafiaSwaggerUIF} />
        <Route path="/async_events_for_mafia" exact component={AsyncAPIForMafia} />

        <Route path="/werewolf_doc" exact component={WerewolfSwaggerUIF} />
        <Route path="/async_events_for_werewolf" exact component={AsyncAPIForWerewolf} />

        <Route path="/resistance_doc" exact component={ResistanceSwaggerUIF} />
        <Route path="/async_events_for_resistance" exact component={AsyncAPIForResistance} />

        <Route path="/avalon_doc" exact component={AvalonSwaggerUIF} />
        <Route path="/async_events_for_avalon" exact component={AsyncAPIForAvalon} />
        
        <Route component={NoMatch}/>
      </Switch>
    </Router>
  );
};

export default Menu;