export const ResistanceAsync = `
asyncapi: 2.0.0
info:
  title: Resistance Game Events Service
  version: 1.0.0
  description: This service only for Resistance game pusher events. Here channels
    name is presence-{game_id}
channels:
  create-channel:
    subscribe:
      message:
        "$ref": "#/components/messages/createAndJoinChannel"
  join-channel:
    subscribe:
      message:
        "$ref": "#/components/messages/createAndJoinChannel"
  leave-channel:
    subscribe:
      message:
        "$ref": "#/components/messages/leaveChannel"
  game_ready:
    subscribe:
      message:
        "$ref": "#/components/messages/gameReady"
  role_selected:
    subscribe:
      message:
        "$ref": "#/components/messages/roleSelected"  
  player_reveal:
    subscribe:
      message:
        "$ref": "#/components/messages/playerReveal"
  select_team:
    subscribe:
      message:
        "$ref": "#/components/messages/selectTeam"
  team_review:
    subscribe:
      message:
        "$ref": "#/components/messages/teamReview"
  review_info:
    subscribe:
      message:
        "$ref": "#/components/messages/reviewInfo"
  team_approved:
    subscribe:
      message:
        "$ref": "#/components/messages/teamApproved"
  team_rejected:
    subscribe:
      message:
        "$ref": "#/components/messages/teamRejected"
  vote_track:
    subscribe:
      message:
        "$ref": "#/components/messages/voteTrack"
  spy_win:
    subscribe:
      message:
        "$ref": "#/components/messages/spyWin"
  mission:
    subscribe:
      message:
        "$ref": "#/components/messages/mission"
  mission_info:
    subscribe:
      message:
        "$ref": "#/components/messages/missionInfo"
  mission_result:
    subscribe:
      message:
        "$ref": "#/components/messages/missionResult"
  show_card:
    subscribe:
      message:
        "$ref": "#/components/messages/showCard"
  game_ended:
    subscribe:
      message:
        "$ref": "#/components/messages/gameEnded"
  play_again:
    subscribe:
      message:
        "$ref": "#/components/messages/playAgain"
components:
  messages:
    createAndJoinChannel:
      payload:
        type: object
        properties:
          message:
            type: string
            description: text message
            example: Mr. John create/join the presence channel        
          name:
            type: string
            description: pusher events name
            example: member_added
          channel:
            type: string
            description: pusher channel name
            example: presence-5ed8a58c6d5cd17a62e39f41
          player_id:
            type: string
            description: player ID
            example: 5ed8a58c6d5cd17a62e39fDD
          player_name:
            type: string
            description: Player Name
            example: Mr. John 
          player_photo_url:
            type: string
            description: Player photo URL
            example: ""
    leaveChannel:
      payload:
        type: object
        properties:
          message:
            type: string
            description: text message
            example: Mr. John leave the presence channel        
          name:
            type: string
            description: pusher events name
            example: member_removed
          channel:
            type: string
            description: pusher channel name
            example: presence-5ed8a58c6d5cd17a62e39f41
          player_id:
            type: string
            description: player ID
            example: 5ed8a58c6d5cd17a62e39fDD
          player_name:
            type: string
            description: Player Name
            example: Mr. John 
          player_photo_url:
            type: string
            description: Player photo URL
            example: ""
    gameReady:
      payload:
        type: object
        properties:
          message:
            type: string
            description: success message
            example: Your role has been assigned
    roleSelected:
      payload:
        type: object
        properties:
          player_id:
            type: string
            description: 5ed8a58c6d5cd17a62e39f41    
    playerReveal:
      payload:
        type: object
        properties:
          message:
            type: string
            description: Mr. John has been seen his role
          player_photo_url:
            type: string
            description: Player photo url
          player_id:
            type: string
            description: 5ed8a58c6d5cd17a62e39f41
          Player_role:
            type: string
            description: Spy
    selectTeam:
      payload:
        type: object
        properties:
          player_id:
            type: string
            description: 5ed8a58c6d5cd17a62e39f41
          team_member:
            type: number
            description: Total team members in a team
            example: 2
    teamReview:
      payload:
        type: object
        properties:
          message:
            type: string
            description: success message
            example: 'Everyone: review the team'
          player_id:
            type: array
            description: Team member's array
            example: "['5ed8a58c6d5cd17a62e39f49', '5ed8a58c6d5cd17a62e39f49']"
    reviewInfo:
      payload:
        type: object
        properties:
          player_id:
            type: string
            description: Player ID
            example: 5ed8a58c6d5cd17a62e39f41
          is_approve:
            type: boolean
            description: Approve status
            example: true
    teamApproved:
      payload:
        type: object
        properties:
          message:
            type: string
            description: Success message
            example: The team has been approved
    teamRejected:
      payload:
        type: object
        properties:
          message:
            type: string
            description: Rejected message
            example: The team has been rejected
    voteTrack:
      payload:
        type: object
        properties:
          vote_rejected:
            type: number
            description: Total vote rejected in a team review
            example: 3
    spyWin:
      payload:
        type: object
        properties:
          message:
            type: string
            description: Spy win message
            example: Spies win the game
    mission:
      payload:
        type: object
        properties:
          player_id:
            type: string
            description: Player's ID
          team_member:
            type: number
            description: Number of players in a team
            example: 3  
    missionInfo:
      payload:
        type: object
        properties:
          player_id:
            type: string
            description: Player's ID
    missionResult:
      payload:
        type: array
        items:
          type: object
          properties:
            Success:
              type: number
              description: number of success mission
              example: 3
            Fail:
              type: number
              description: number of fail mission
              example: 0
            mission_1:
              type: string
              description: Status of mission 1
              example: Success
            mission_2:
              type: string
              description: Status of mission 2
              example: Success
            mission_3:
              type: string
              description: Status of mission 3
              example: Success
            mission_4:
              type: string
              description: Status of mission 4
              example: Fail
            mission_5:
              type: string
              description: Status of mission 5
              example: Pending
    showCard:
      payload:
        type: object
        properties:
          card_number:
            type: number
            description: Card number
            example: 1
          is_success:
            type: boolean
            description: status of the card, like it's true/false
            example: true
    gameEnded:
      payload:
        type: object
        properties:
          player_id:
            type: string
            description: Player ID
            example: 5ed8a58c6d5cd17a62e39f41
    playAgain:
      payload:
        type: object
        properties:
          message:
            type: string
            description: text message
            example: John has joined to play again
          player_id:
            type: string
            description: Player ID
            example: 5ed8a58c6d5cd17a62e39f41
`;