import React from "react";
import AsyncApiComponent from "@kyma-project/asyncapi-react";

import { WerewolfAsync } from "../async_werewolf";
import "@kyma-project/asyncapi-react/lib/styles/fiori.css";
import "./../style.css";

const AsyncAPIForWerewolf = () => {
	return(
		<AsyncApiComponent schema={WerewolfAsync} />
	)
}

export default AsyncAPIForWerewolf;