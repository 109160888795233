import React from "react";
import { Link } from "react-router-dom";

const Nav = () => {
  const navStyle = {
    color: "white",
    textDecorationLine: "none",
    textDecorationStyle: "solid"
  };

  return (
    <nav  bg="dark" variant="dark">
      <ul className="nav-line">
        <Link to="/" style={navStyle}>
          <li>Universal Docs</li>
        </Link>
		    <Link to="/mafia_doc" style={navStyle}>
          <li>Mafia Docs</li>
        </Link>
		    <Link to="/async_events_for_mafia" style={navStyle}>
          <li>Mafia Events</li>
        </Link>
        <Link to="/werewolf_doc" style={navStyle}>
          <li>Werewolf Docs</li>
        </Link>
		    <Link to="/async_events_for_werewolf" style={navStyle}>
          <li>Werewolf Events</li>
        </Link>
        <Link to="/resistance_doc" style={navStyle}>
          <li>Resistance Docs</li>
        </Link>
		    <Link to="/async_events_for_resistance" style={navStyle}>
          <li>Resistance Events</li>
        </Link>
        <Link to="/avalon_doc" style={navStyle}>
          <li>Avalon Docs</li>
        </Link>
		    <Link to="/async_events_for_avalon" style={navStyle}>
          <li>Avalon Events</li>
        </Link>
        
      </ul>
    </nav>
  );
};

export default Nav;
