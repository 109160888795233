export const MafiaAsync = `
asyncapi: 2.0.0
info:
  title: Mafia Game Events Service
  version: 1.0.0
  description: This service only for Mafia game pusher events. Here channels name
    is presence-{game_id}
channels:
  create-channel:
    subscribe:
      message:
        "$ref": "#/components/messages/createAndJoinChannel"
  join-channel:
    subscribe:
      message:
        "$ref": "#/components/messages/createAndJoinChannel"
  leave-channel:
    subscribe:
      message:
        "$ref": "#/components/messages/leaveChannel"
  game_ready:
    subscribe:
      message:
        "$ref": "#/components/messages/gameReady"
  role_selected:
    subscribe:
      message:
        "$ref": "#/components/messages/roleSelected"        
  player_reveal:
    subscribe:
      message:
        "$ref": "#/components/messages/playerReveal"
  mafia_turn:
    subscribe:
      message:
        "$ref": "#/components/messages/mafiaTurn"
  detective_turn:
    subscribe:
      message:
        "$ref": "#/components/messages/detectiveTurn"
  doctor_turn:
    subscribe:
      message:
        "$ref": "#/components/messages/doctorTurn"
  witch_turn:
    subscribe:
      message:
        "$ref": "#/components/messages/witchTurn"
  night_result:
    subscribe:
      message:
        "$ref": "#/components/messages/nightResult"
  mafia_killed_nobody:
    subscribe:
      message:
        "$ref": "#/components/messages/mafiaKilledNobody"
  mafia_win:
    subscribe:
      message:
        "$ref": "#/components/messages/mafiaWin"
  villagers_win:
    subscribe:
      message:
        "$ref": "#/components/messages/villagersWin"
  day_result:
    subscribe:
      message:
        "$ref": "#/components/messages/dayResult"
  vote_info:
    subscribe:
      message:
        "$ref": "#/components/messages/voteInfo"
  tied_votes:
    subscribe:
      message:
        "$ref": "#/components/messages/tiedVotes"
  villagers_killed_nobody:
    subscribe:
      message:
        "$ref": "#/components/messages/villagersKilledNobody"
  everyone_sleep:
    subscribe:
      message:
        "$ref": "#/components/messages/everyoneSleep"
  game_ended:
    subscribe:
      message:
        "$ref": "#/components/messages/gameEnded"
  player_quit:
    subscribe:
      message:
        "$ref": "#/components/messages/playerQuit"
  all_mafia_turn:
    subscribe:
      message:
        "$ref": "#/components/messages/allMafiaTurn"
  everyone_wakeup:
    subscribe:
      message:
        "$ref": "#/components/messages/everyoneWakeup"
  villagers_discussion:
    subscribe:
      message:
        "$ref": "#/components/messages/villagersDiscussion"
  psycho_turn:
    subscribe:
      message:
        "$ref": "#/components/messages/psychoTurn"
  mafia_sleep:
    subscribe:
      message:
        "$ref": "#/components/messages/mafiaSleep"
  doctor_sleep:
    subscribe:
      message:
        "$ref": "#/components/messages/doctorSleep"
  psycho_result:
    subscribe:
      message:
        "$ref": "#/components/messages/psychoResult"
components:
  messages:
    createAndJoinChannel:
      payload:
        type: object
        properties:
          message:
            type: string
            description: text message
            example: Mr. John create/join the presence channel        
          name:
            type: string
            description: pusher events name
            example: member_added
          channel:
            type: string
            description: pusher channel name
            example: presence-5ed8a58c6d5cd17a62e39f41
          player_id:
            type: string
            description: player ID
            example: 5ed8a58c6d5cd17a62e39fDD
          player_name:
            type: string
            description: Player Name
            example: Mr. John 
          player_photo_url:
            type: string
            description: Player photo URL
            example: ""
    leaveChannel:
      payload:
        type: object
        properties:
          message:
            type: string
            description: text message
            example: Mr. John leave the presence channel        
          name:
            type: string
            description: pusher events name
            example: member_removed
          channel:
            type: string
            description: pusher channel name
            example: presence-5ed8a58c6d5cd17a62e39f41
          player_id:
            type: string
            description: player ID
            example: 5ed8a58c6d5cd17a62e39fDD
          player_name:
            type: string
            description: Player Name
            example: Mr. John 
          player_photo_url:
            type: string
            description: Player photo URL
            example: ""
    gameReady:
      payload:
        type: object
        properties:
          message:
            type: string
            description: success message
            example: Your role has been assigned
    roleSelected:
      payload:
        type: object
        properties:
          player_id:
            type: string
            description: 5ed8a58c6d5cd17a62e39f41
    playerReveal:
      payload:
        type: object
        properties:
          message:
            type: string
            description: Mr. John has been seen his role
          player_photo_url:
            type: string
            description: Player photo url
          player_id:
            type: string
            description: 5ed8a58c6d5cd17a62e39f41
          Player_role:
            type: string
            description: Mafia
    mafiaTurn:
      payload:
        type: object
        properties:
          player_id:
            type: array
            description: Mafia players array
            example: "['5ed8a58c6d5cd17a62e39f49', '5ed8a58c6d5cd17a62e39f49']"
    detectiveTurn:
      payload:
        type: object
        properties:
          player_id:
            type: string
            description: Player ID
            example: 5ed8a58c6d5cd17a62e39f41
          active:
            type: boolean
    doctorTurn:
      payload:
        type: object
        properties:
          player_id:
            type: string
            description: Player ID
            example: 5ed8a58c6d5cd17a62e39f41
          active:
            type: boolean
    witchTurn:
      payload:
        type: object
        properties:
          player_id:
            type: string
            description: Player ID
            example: 5ed8a58c6d5cd17a62e39f41
          active:
            type: boolean
          mafia_target_id:
            type: string
            description: Player ID
            example: 5ed8a58c6d5cd17a62e39f41
          kill:
            type: boolean
          save:
            type: boolean
    nightResult:
      payload:
        type: array
        items:
        - type: object
          properties:
            player_id:
              type: string
              description: Player ID
              example: 5ed8a58c6d5cd17a62e39f41
            active:
              type: string
              description: killed
            action_by:
              type: string
              description: mafia
        - type: object
          properties:
            player_id:
              type: string
              description: Player ID
              example: 5ed8a58c6d5cd17a62e39f41
            active:
              type: string
              description: saved
            action_by:
              type: string
              description: doctor/witch
    mafiaKilledNobody:
      payload:
        type: object
        properties:
          message:
            type: string
            description: Mafia killed nobody
    mafiaWin:
      payload:
        type: object
        properties:
          message:
            type: string
            description: Mafia win
    villagersWin:
      payload:
        type: object
        properties:
          message:
            type: string
            description: Villagers win
    dayResult:
      payload:
        type: array
        items:
          type: object
          properties:
            player_id:
              type: string
              description: Player ID
              example: 5ed8a58c6d5cd17a62e39f41
            active:
              type: string
              description: killed
            action_by:
              type: string
              description: villager
    voteInfo:
      payload:
        type: object
        properties:
          voter_id:
            type: string
            description: Player ID
            example: 5ed8a58c6d5cd17a62e39f41
          target_id:
            type: string
            description: Player ID
            example: 5ed8a58c6d5cd17a62e39f41
    tiedVotes:
      payload:
        type: object
        properties:
          player_id1:
            type: string
            description: Player ID
            example: 5ed8a58c6d5cd17a62e39f41
          player_id2:
            type: string
            description: Player ID
            example: 5ed8a58c6d5cd17a62e39f42
    villagersKilledNobody:
      payload:
        type: object
        properties:
          message:
            type: string
            description: villager killed nobody
    everyoneSleep:
      payload:
        type: object
        properties:
          message:
            type: string
            description: 'Everyone: Go to Sleep!'
    gameEnded:
      payload:
        type: object
        properties:
          player_id:
            type: string
            description: Player ID
            example: 5ed8a58c6d5cd17a62e39f41
    playerQuit:
      payload:
        type: object
        properties:
          message:
            type: string
            description: Mr. X leave from the L2EDHM channel
          player_id:
            type: string
            description: Player ID
            example: 5ed8a58c6d5cd17a62e39f41
    allMafiaTurn:
      payload:
        type: object
        properties:
          message:
            type: string
            description: Your role has been assigned
    everyoneWakeup:
      payload:
        type: object
        properties:
          player_id:
            type: array
            description: Player ID
            example: ['5ed8a58c6d5cd17a62e39f49', '5ed8a58c6d5cd17a62e39f49']
    villagersDiscussion:
      payload:
        type: object
        properties:
          message:
            type: string
            description: villagers discussion
    psychoTurn:
      payload:
        type: object
        properties:
          player_id:
            type: string
            description: Player ID
            example: 5ed8a58c6d5cd17a62e39f41
          active:
            type: boolean
            description: 'true'
    mafiaSleep:
      payload:
        type: object
        properties:
          player_id:
            type: string
            description: Player ID
            example: 5ed8a58c6d5cd17a62e39f41
          action:
            type: string
            description: killed
          action_by:
            type: string
            description: mafia
          serial_no:
            type: number
            description: '1'
    doctorSleep:
      payload:
        type: object
        properties:
          player_id:
            type: string
            description: Player ID
            example: 5ed8a58c6d5cd17a62e39f41
          action:
            type: string
            description: saved
          action_by:
            type: string
            description: Doctor
    psychoResult:
      payload:
        type: object
        properties:
          player_id:
            type: string
            description: Player ID
            example: 5ed8a58c6d5cd17a62e39f41
          action:
            type: string
            description: killed
          action_by:
            type: string
            description: psycho
`;