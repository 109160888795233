export const WerewolfAsync = `
asyncapi: 2.0.0
info:
  title: Werewolf Game Events Service
  version: 1.0.0
  description: This service only for Werewolf game pusher events. Here channels name
    is presence-{game_id}
channels:
  create-channel:
    subscribe:
      message:
        "$ref": "#/components/messages/createAndJoinChannel"
  join-channel:
    subscribe:
      message:
        "$ref": "#/components/messages/createAndJoinChannel"
  leave-channel:
    subscribe:
      message:
        "$ref": "#/components/messages/leaveChannel"
  game_ready:
    subscribe:
      message:
        "$ref": "#/components/messages/gameReady"
  role_selected:
    subscribe:
      message:
        "$ref": "#/components/messages/roleSelected"
  player_reveal:
    subscribe:
      message:
        "$ref": "#/components/messages/playerReveal"
  
  werewolf_turn:
    subscribe:
      message:
        "$ref": "#/components/messages/werewolfTurn"
  seer_turn:
    subscribe:
      message:
        "$ref": "#/components/messages/seerTurn"
  doctor_turn:
    subscribe:
      message:
        "$ref": "#/components/messages/doctorTurn"
  witch_turn:
    subscribe:
      message:
        "$ref": "#/components/messages/witchTurn"
  night_result:
    subscribe:
      message:
        "$ref": "#/components/messages/nightResult"
  werewolf_killed_nobody:
    subscribe:
      message:
        "$ref": "#/components/messages/werewolfKilledNobody"
  werewolf_win:
    subscribe:
      message:
        "$ref": "#/components/messages/werewolfWin"
  villagers_win:
    subscribe:
      message:
        "$ref": "#/components/messages/villagersWin"
  fool_win:
    subscribe:
      message:
        "$ref": "#/components/messages/foolWin"
  day_result:
    subscribe:
      message:
        "$ref": "#/components/messages/dayResult"
  vote_info:
    subscribe:
      message:
        "$ref": "#/components/messages/voteInfo"
  tied_votes:
    subscribe:
      message:
        "$ref": "#/components/messages/tiedVotes"
  revote:
    subscribe:
      message:
        "$ref": "#/components/messages/revote"        
  villagers_killed_nobody:
    subscribe:
      message:
        "$ref": "#/components/messages/villagersKilledNobody"
  everyone_sleep:
    subscribe:
      message:
        "$ref": "#/components/messages/everyoneSleep"
  ready_to_vote:
    subscribe:
      message:
        "$ref": "#/components/messages/readyToVote"
  priest_decide:
    subscribe:
      message:
        "$ref": "#/components/messages/priestDecide"
  priest_results:
    subscribe:
      message:
        "$ref": "#/components/messages/priestResults"
  game_ended:
    subscribe:
      message:
        "$ref": "#/components/messages/gameEnded"
  player_quit:
    subscribe:
      message:
        "$ref": "#/components/messages/playerQuit"
  all_werewolf_turn:
    subscribe:
      message:
        "$ref": "#/components/messages/allwerewolfTurn"
  everyone_wakeup:
    subscribe:
      message:
        "$ref": "#/components/messages/everyoneWakeup"
  villagers_discussion:
    subscribe:
      message:
        "$ref": "#/components/messages/villagersDiscussion"
  angel_night:
    subscribe:
      message:
        "$ref": "#/components/messages/angelNight"
  moderator_night_result:
    subscribe:
      message:
        "$ref": "#/components/messages/nightResult"
  moderator_day_result:
    subscribe:
      message:
        "$ref": "#/components/messages/dayResult"
  moderator_tied_votes:
    subscribe:
      message:
        "$ref": "#/components/messages/tiedVotes"  
  moderator_villagers_killed_nobody:
    subscribe:
      message:
        "$ref": "#/components/messages/villagersKilledNobody"
  moderator_werewolf_killed_nobody:
    subscribe:
      message:
        "$ref": "#/components/messages/werewolfKilledNobody"
  moderator_angel_night:
    subscribe:
      message:
        "$ref": "#/components/messages/angelNight"
  moderator_werewolf_win:
    subscribe:
      message:
        "$ref": "#/components/messages/werewolfWin"
  moderator_villagers_win:
    subscribe:
      message:
        "$ref": "#/components/messages/villagersWin"
  moderator_fool_win:
    subscribe:
      message:
        "$ref": "#/components/messages/foolWin"
  moderator_priest_results:
    subscribe:
      message:
        "$ref": "#/components/messages/priestResults"
  psycho_turn:
    subscribe:
      message:
        "$ref": "#/components/messages/psychoTurn"
  werewolf_sleep:
    subscribe:
      message:
        "$ref": "#/components/messages/werewolfSleep"
  doctor_sleep:
    subscribe:
      message:
        "$ref": "#/components/messages/doctorSleep"
  witch_sleep:
    subscribe:
      message:
        "$ref": "#/components/messages/witchSleep"
  seer_sleep:
    subscribe:
      message:
        "$ref": "#/components/messages/seerSleep"        
  psycho_result:
    subscribe:
      message:
        "$ref": "#/components/messages/psychoResult"
components:
  messages:
    createAndJoinChannel:
      payload:
        type: object
        properties:
          message:
            type: string
            description: text message
            example: Mr. John create/join the presence channel        
          name:
            type: string
            description: pusher events name
            example: member_added
          channel:
            type: string
            description: pusher channel name
            example: presence-5ed8a58c6d5cd17a62e39f41
          player_id:
            type: string
            description: player ID
            example: 5ed8a58c6d5cd17a62e39fDD
          player_name:
            type: string
            description: Player Name
            example: Mr. John 
          player_photo_url:
            type: string
            description: Player photo URL
            example: ""
    leaveChannel:
      payload:
        type: object
        properties:
          message:
            type: string
            description: text message
            example: Mr. John leave the presence channel        
          name:
            type: string
            description: pusher events name
            example: member_removed
          channel:
            type: string
            description: pusher channel name
            example: presence-5ed8a58c6d5cd17a62e39f41
          player_id:
            type: string
            description: player ID
            example: 5ed8a58c6d5cd17a62e39fDD
          player_name:
            type: string
            description: Player Name
            example: Mr. John 
          player_photo_url:
            type: string
            description: Player photo URL
            example: ""
    gameReady:
      payload:
        type: object
        properties:
          message:
            type: string
            description: success message
            example: Your role has been assigned
    playerReveal:
      payload:
        type: object
        properties:
          message:
            type: string
            description: Mr. John has been seen his role
          player_photo_url:
            type: string
            description: Player photo url
          player_id:
            type: string
            description: 5ed8a58c6d5cd17a62e39f41
          Player_role:
            type: string
            description: Werewolf

    roleSelected:
      payload:
        type: object
        properties:
          player_id:
            type: string
            description: 5ed8a58c6d5cd17a62e39f41
    werewolfTurn:
      payload:
        type: object
        properties:
          player_id:
            type: array
            description: Werewolf players array
            example: "['5ed8a58c6d5cd17a62e39f49', '5ed8a58c6d5cd17a62e39f49']"
    seerTurn:
      payload:
        type: object
        properties:
          player_id:
            type: string
            description: Player ID
            example: 5ed8a58c6d5cd17a62e39f41
          active:
            type: boolean
    doctorTurn:
      payload:
        type: object
        properties:
          player_id:
            type: string
            description: Player ID
            example: 5ed8a58c6d5cd17a62e39f41
          active:
            type: boolean
    witchTurn:
      payload:
        type: object
        properties:
          player_id:
            type: string
            description: Player ID
            example: 5ed8a58c6d5cd17a62e39f41
          active:
            type: boolean
          werewolf_target_id:
            type: string
            description: Player ID
            example: 5ed8a58c6d5cd17a62e39f41
          kill:
            type: boolean
          save:
            type: boolean
    nightResult:
      payload:
        type: array
        items:
        - type: object
          properties:
            player_id:
              type: string
              description: Player ID
              example: 5ed8a58c6d5cd17a62e39f41
            active:
              type: string
              description: killed
            action_by:
              type: string
              description: werewolf
        - type: object
          properties:
            player_id:
              type: string
              description: Player ID
              example: 5ed8a58c6d5cd17a62e39f41
            active:
              type: string
              description: saved
            action_by:
              type: string
              description: doctor/witch
    werewolfKilledNobody:
      payload:
        type: object
        properties:
          message:
            type: string
            description: Werewolf killed nobody
    werewolfWin:
      payload:
        type: object
        properties:
          message:
            type: string
            description: Werewolf win
    villagersWin:
      payload:
        type: object
        properties:
          message:
            type: string
            description: Villagers win
    dayResult:
      payload:
        type: array
        items:
          type: object
          properties:
            player_id:
              type: string
              description: Player ID
              example: 5ed8a58c6d5cd17a62e39f41
            active:
              type: string
              description: killed
            action_by:
              type: string
              description: villager
    voteInfo:
      payload:
        type: object
        properties:
          voter_id:
            type: string
            description: Player ID
            example: 5ed8a58c6d5cd17a62e39f41
          target_id:
            type: string
            description: Player ID
            example: 5ed8a58c6d5cd17a62e39f41
    revote:
      payload:
        type: object
        properties:
          message:
            type: string
            description: Revote messate
    tiedVotes:
      payload:
        type: object
        properties:
          player_id1:
            type: string
            description: Player ID
            example: 5ed8a58c6d5cd17a62e39f41
          player_id2:
            type: string
            description: Player ID
            example: 5ed8a58c6d5cd17a62e39f42
    villagersKilledNobody:
      payload:
        type: object
        properties:
          message:
            type: string
            description: villager killed nobody
    everyoneSleep:
      payload:
        type: object
        properties:
          message:
            type: string
            description: 'Everyone: Go to Sleep!'
    readyToVote:
      payload:
        type: object
        properties:
          message:
            type: string
            description: ''
            example: Ready to vote, who is a werewolf ?
    priestDecide:
      payload:
        type: object
        properties:
          message:
            type: string
            description: ''
            example: Priest has decide to throw holy water
    priestResults:
      payload:
        type: object
        properties:
          priest_player_id:
            type: string
            description: Priest Player ID
            example: 5ed8a58c6d5cd17a62e39f41
          player_id:
            type: string
            description: killed player ID
            example: 5ed8a58c6d5cd17a62e39f41
          successfull:
            type: boolean
            description: Priest action correct or not
            example: true/false
    gameEnded:
      payload:
        type: object
        properties:
          player_id:
            type: string
            description: Player ID
            example: 5ed8a58c6d5cd17a62e39f41
    playerQuit:
      payload:
        type: object
        properties:
          message:
            type: string
            description: Mr. X leave from the L2EDHM channel
          player_id:
            type: string
            description: Player ID
            example: 5ed8a58c6d5cd17a62e39f41
    allwerewolfTurn:
      payload:
        type: object
        properties:
          message:
            type: string
            description: Your role has been assigned
    everyoneWakeup:
      payload:
        type: object
        properties:
          player_id:
            type: array
            description: Player ID
            example: "['5ed8a58c6d5cd17a62e39f49', '5ed8a58c6d5cd17a62e39f49']"
    villagersDiscussion:
      payload:
        type: object
        properties:
          message:
            type: string
            description: villagers discussion
    angelNight:
      payload:
        type: object
        properties:
          witch_turn:
            type: boolean
            description: it will be informed to moderator witch turn will or not
            example: 'true'
    foolWin:
      payload:
        type: object
        properties:
          message:
            type: string
            description: Fool win
    psychoTurn:
      payload:
        type: object
        properties:
          player_id:
            type: string
            description: Player ID
            example: 5ed8a58c6d5cd17a62e39f41
          active:
            type: boolean
            description: 'true'
    werewolfSleep:
      payload:
        type: object
        properties:
          player_id:
            type: string
            description: Player ID
            example: 5ed8a58c6d5cd17a62e39f41
          action:
            type: string
            description: killed
          action_by:
            type: string
            description: werewolf
          serial_no:
            type: number
            description: '1'
    doctorSleep:
      payload:
        type: object
        properties:
          player_id:
            type: string
            description: Player ID
            example: 5ed8a58c6d5cd17a62e39f41
          action:
            type: string
            description: saved
          action_by:
            type: string
            description: Doctor
    witchSleep:
      payload:
        type: object
        properties:
          player_id:
            type: string
            description: Player ID
            example: 5ed8a58c6d5cd17a62e39f41
          action:
            type: string
            description: it will be skipped/killed/saved
          action_by:
            type: string
            description: Witch
    seerSleep:
      payload:
        type: object
        properties:
          message:
            type: string
            description: message parameter only get when Seer left from the game
          player_id:
            type: string
            description: Player ID
            example: 5ed8a58c6d5cd17a62e39f41
          action:
            type: string
            description: it will be investigate
          action_by:
            type: string
            description: Seer
    psychoResult:
      payload:
        type: object
        properties:
          player_id:
            type: string
            description: 5ed8a58c6d5cd17a62e39f42
          action:
            type: string
            description: killed
          action_by:
            type: string
            description: psycho
`;