import React from "react";
import AsyncApiComponent from "@kyma-project/asyncapi-react";

import { MafiaAsync } from "../async_mafia";
import "@kyma-project/asyncapi-react/lib/styles/fiori.css";
import "./../style.css";

const AsyncAPIForMafia = () => {
	return(
		<AsyncApiComponent schema={MafiaAsync} />
	)
}

export default AsyncAPIForMafia;